import React from "react";

export default function ShoppingCartIcon(props) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="presentation"
      width="28"
      height="26"
      viewBox="0 10 28 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M26.15 14.488L6.977 13.59l-.666-2.661C6.159 10.37 5.704 10 5.127 10H1.213C.547 10 0 10.558 0 11.238c0 .68.547 1.238 1.213 1.238h2.974l3.337 13.249-.82 3.465c-.092.371 0 .774.212 1.053.243.31.576.465.94.465H22.72c.667 0 1.214-.558 1.214-1.239 0-.68-.547-1.238-1.214-1.238H9.434l.333-1.423 12.135-.589c.455-.03.85-.31 1.032-.712l4.247-9.286c.181-.34.151-.774-.06-1.144-.212-.34-.577-.589-.97-.589zM22.297 36c-1.256 0-2.275-1.04-2.275-2.321 0-1.282 1.019-2.322 2.275-2.322s2.275 1.04 2.275 2.322c0 1.281-1.02 2.321-2.275 2.321zM10.92 33.679C10.92 34.96 9.9 36 8.646 36 7.39 36 6.37 34.96 6.37 33.679c0-1.282 1.019-2.322 2.275-2.322s2.275 1.04 2.275 2.322z"
      ></path>
    </svg>
  );
}
